import { dev } from '$app/environment';
import { handleErrorWithSentry, Replay } from '@sentry/sveltekit';
import * as Sentry from '@sentry/sveltekit';

if (!dev) {
	Sentry.init({
		dsn: 'https://699b702714cd45589f0a54564cfe5413@o4504980466499584.ingest.sentry.io/4505413775917056',
		tracesSampleRate: 1.0,

		// This sets the sample rate to be 10%. You may want this to be 100% while
		// in development and sample at a lower rate in production
		replaysSessionSampleRate: 1,

		// If the entire session is not sampled, use the below sample rate to sample
		// sessions when an error occurs.
		replaysOnErrorSampleRate: 1.0,

		// If you don't want to use Session Replay, just remove the line below:
		integrations: [new Replay()]
	});
}

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = dev ? handleErrorWithSentry() : () => {};
